
/* Swipers */

// reviews
const reviews4Swiper = new Swiper(".reviews4Swiper", {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
        nextEl: ".reviews__swiper-button-next",
        prevEl: ".reviews__swiper-button-prev",
    },
    pagination: {
        el: ".reviews__swiper-pagination",
    },
    breakpoints: {
        1160: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        900: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
    },
});

// doctors
const doctors1Swiper = new Swiper(".doctorsSwiper", {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
        nextEl: ".doctors__swiper-button-next",
        prevEl: ".doctors__swiper-button-prev",
    },
    pagination: {
        el: ".doctors__swiper-pagination",
    },
    breakpoints: {
        1180: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        910: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        688: {
            slidesPerView: 2,
        },
    },
});


// licenses
const licenses3Swiper = new Swiper(".licenses3Swiper", {
    loop: true,
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    coverflowEffect: {
        rotate: 0,
        stretch: 100,
        depth: 10,
        modifier: 1,
        slideShadows: false,
    },
    navigation: {
        nextEl: ".licenses__swiper-button-next",
        prevEl: ".licenses__swiper-button-prev",
    },
    pagination: {
        el: ".licenses__swiper-pagination",
    },
    breakpoints: {
        1100: {
            slidesPerView: 3,
            spaceBetween: 0,
        },
        600: {
            slidesPerView: 2,
        },
        300: {
            slidesPerView: 1.6,
        },
    },
});


/* End swipers */